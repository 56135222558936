import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router";
import useToggleSaveProgram from "hooks/ProgramsV2/useToggleSaveProgram";
import Img from "app/components/Img";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Icon, { Heart, HeartFilled, Lock } from "app/components/Icon";
import Button from "app/components/Button";
import { H2 } from "app/components/Typography";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import { userSelectsClassAction } from "modules/content";
import { useAccountMode } from "hooks/useAccountMode";
import InfoTag from "./InfoTag";
import { START_CLASS_1, RESUME_PROGRAM } from "../constants";
import { openTrailer } from "../../../../modules/program-view";

const TransparentButton = styled(Button)`
  background-color: transparent;
  border: 1px solid white;
  width: 92px;
  height: 40px;
  opacity: 0.9;

  ${({ theme }) => theme.mediaQueries.ltsm} {
    width: 142px;
  }

  @media (hover: hover) {
    :hover {
      background-color: ${({ theme }) => theme.colors.monochrome[7]};
    }
  }
`;

const PreviewOverlay = () => {
  const toggleSaveProgramMutation = useToggleSaveProgram();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [programsV2Data, loading] = useGetProgramsV2({
    variables: { slug },
  });
  const isSubscribedUser = useSelector(
    ({ user }) => user.subscription.activeSubscription
  );
  const { accountMode } = useAccountMode();

  const {
    slug: programSlug,
    isSaved: isProgramSaved,
    averageSessionTime,
    level,
    totalSectionCount,
    title,
    content,
    progress,
    isFree,
  } = programsV2Data;
  const { assets } = content || {};
  const { titleSvgURL } = assets || {};
  const { startedDate, queuedClass } = progress || {};
  const showLockIcon = !isSubscribedUser && !isFree;

  if (!programsV2Data || loading) {
    return <></>;
  }

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      mb={3}
      bottom={{ _: 5, sm: 0 }}
      justifyContent="center"
      alignItems="center"
    >
      {titleSvgURL ? (
        <Img
          src={titleSvgURL}
          width="auto"
          height={{ _: "150px", sm: "250px" }}
        />
      ) : (
        <H2 color="white" mb={3}>
          {title}
        </H2>
      )}
      <Flex
        width={{ _: "100%", sm: "448px" }}
        height="96px"
        px={{ _: 3, sm: 0 }}
        mb={5}
        flexDirection="column"
      >
        <Flex
          flexDirection={{ _: "column", sm: "row" }}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          mb={{ _: 4, sm: 3 }}
          style={{ zIndex: 1 }}
        >
          <Flex
            width="100%"
            justifyContent="space-around"
            mb={{ _: 3, sm: 0 }}
            flex="65%"
          >
            <InfoTag icon={level}>{level}</InfoTag>
            <InfoTag icon="Alarm">{totalSectionCount} days total</InfoTag>
          </Flex>
          <Flex flex="35%" justifyContent={{ _: "center", sm: "flex-end" }}>
            <InfoTag icon="Schedule">{averageSessionTime}</InfoTag>
          </Flex>
        </Flex>
        <Flex
          flexDirection={{ _: "column", sm: "row" }}
          width={{ _: "300px", sm: "100%" }}
        >
          <Button
            mr={{ _: 0, sm: 3 }}
            width={{ _: "100%", sm: "204px" }}
            height="40px"
            mb={{ _: 3, sm: 0 }}
            onClick={() =>
              dispatch(
                userSelectsClassAction({
                  programData: programsV2Data,
                  classData: queuedClass,
                  accountMode,
                })
              )
            }
          >
            <Flex alignItems="center">
              {showLockIcon && (
                <Icon
                  as={Lock}
                  color="white"
                  width="24px"
                  height="20px"
                  mr={2}
                  mb={1}
                />
              )}
              {startedDate ? RESUME_PROGRAM : START_CLASS_1}
            </Flex>
          </Button>
          <Flex>
            <TransparentButton
              mr={3}
              onClick={() =>
                toggleSaveProgramMutation({
                  variables: { programSlug, willSave: !isProgramSaved },
                  programData: programsV2Data,
                  component: "ProgramPreviewBanner",
                })
              }
            >
              <Flex alignItems="center">
                <Icon
                  as={isProgramSaved ? HeartFilled : Heart}
                  color="white"
                  width="16px"
                  height="16px"
                />
                <Div ml={2}>{isProgramSaved ? "Saved" : "Save"} </Div>
              </Flex>
            </TransparentButton>
            <TransparentButton mr={0} onClick={() => dispatch(openTrailer())}>
              Trailer
            </TransparentButton>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PreviewOverlay;
