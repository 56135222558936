import React from "react";
import PropTypes from "prop-types";
import Icon, {
  LevelBeginner,
  LevelIntermediate,
  LevelAdvanced,
  Alarm,
  Schedule,
} from "app/components/Icon";
import Flex from "app/components/Flex";
import { H5 } from "app/components/Typography";

const ICON_MAP = {
  Beginner: LevelBeginner,
  Intermediate: LevelIntermediate,
  Advanced: LevelAdvanced,
  Alarm,
  Schedule,
};

const InfoTag = ({ children, icon }) => (
  <Flex alignItems="center">
    <Icon height="17px" width="17px" color="white" mr={3} as={ICON_MAP[icon]} />
    <H5 color="white">{children}</H5>
  </Flex>
);

InfoTag.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
};

export default InfoTag;
