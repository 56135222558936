import { gql, useMutation } from "@apollo/client";
import { TOGGLE_SAVE_PROGRAM_MUTATION } from "graphql/mutations";
import { contentToggleSaveProgram } from "services/typewriter/segment";

export default () => {
  const [toggleSaveProgramMutation] = useMutation(TOGGLE_SAVE_PROGRAM_MUTATION);

  return async ({ component, variables, programData }) => {
    const { programSlug, willSave } = variables;
    const mutationResults = await toggleSaveProgramMutation({
      variables: {
        programSlug,
        willSave,
      },
      optimisticResponse: {
        toggleSaveProgram: {
          slug: programSlug,
          isSaved: willSave,
          __typename: "ProgramV2",
        },
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `ProgramV2:{"slug":"${data?.toggleSaveProgram?.slug}"}`,
          fragment: gql`
            fragment saveProgramV2 on ProgramV2 {
              isSaved
            }
          `,
          data: {
            isSaved: data?.toggleSaveProgram?.isSaved,
          },
        });
      },
    });

    contentToggleSaveProgram({
      program_slug: programData.slug,
      program_title: programData.title,
      component,
      saved: willSave,
      style: programData.style,
      level: programData.difficulty,
      categories: programData.categories,
    });

    return mutationResults;
  };
};
